/**
 * The Home container
 */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

// import {setLanguage} from "redux-i18n";
import Hero from "../../components/Hero";
import Page from "../../components/Page";
import CMSBlock from "../../components/CMSBlock";
import Perks from "../../components/Perks";
import { loadHome } from "../../redux/xapis";
import cmsBlocksSelectors from "../../redux/cmsblocks/selectors";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import { useSsrEffect } from "@issr/core";
const _ = {
  orderBy: orderBy,
  filter: filter,
};
/**
 * Home container used as app default landing page
 */
const Home = (props, context) => {
  const dispatch = useDispatch();
  const language = useSelector((state) => {
    return state.i18nState.lang;
  });

  const homeCMSBlocks = useSelector((state) =>
    cmsBlocksSelectors.selectCMSBlocksForPage(
      state,
      state.xapis.homeCMSBlocksSlugs
    )
  );
  const frontload = async () => {
    if (homeCMSBlocks.length > 0) return;
    dispatch(loadHome());
  };

  useSsrEffect(frontload, [language]);

  const introBlock = _.filter(homeCMSBlocks, function (cmsBlock) {
    return cmsBlock.slug === "home_intro";
  });
  var homeTeaserCMSBlocks = _.filter(homeCMSBlocks, function (cmsBlock) {
    return cmsBlock.template === "teaser_tile";
  });
  homeTeaserCMSBlocks = _.orderBy(homeTeaserCMSBlocks, ["slug"], ["asc"]);
  const higlightBlock1 = _.filter(homeCMSBlocks, function (cmsBlock) {
    return cmsBlock.slug === "home_highlight_1";
  });
  const higlightBlock2 = _.filter(homeCMSBlocks, function (cmsBlock) {
    return cmsBlock.slug === "home_highlight_2";
  });
  const aboutBlock = _.filter(homeCMSBlocks, function (cmsBlock) {
    return cmsBlock.slug === "home_about";
  });
  return (
    <Page
      id="home"
      title={context.t("Order stickers and adhesive film online at myfolie")}
      lang={language}
      description={context.t(
        "Now create your adhesive labels and adhesive letters online. ✓individual ✓ many fonts ✓ many colors ✓ on account"
      )}
      image="https://cdn.myfolie.de/media/banner/800x500.png"
    >
      <Hero lang={language} />
      <Perks />
      {introBlock[0] && (
        <Container className="my-5">
          <CMSBlock cmsBlock={introBlock[0]} />
        </Container>
      )}
      <Container className="my-5">
        <h2>{context.t("Our top products at a glance")}</h2>
        <Row>
          {homeTeaserCMSBlocks.map((block) => (
            <Col xl={4} md={6} key={block.id}>
              <CMSBlock key={`home_cms_block_${block.id}`} cmsBlock={block} />
            </Col>
          ))}
        </Row>
      </Container>
      {higlightBlock1[0] && (
        <Container className="my-5">
          <CMSBlock cmsBlock={higlightBlock1[0]} />
        </Container>
      )}
      {higlightBlock2[0] && (
        <Container className="my-5 bg-light">
          <CMSBlock cmsBlock={higlightBlock2[0]} invert />
        </Container>
      )}
      {aboutBlock[0] && (
        <Container className="my-5">
          <CMSBlock cmsBlock={aboutBlock[0]} />
        </Container>
      )}
    </Page>
  );
};

/**  define proptype for the 'translation' function  */
Home.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default Home;
